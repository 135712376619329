import React from 'react';
import { Row } from 'react-bootstrap';
import { APPLICANT_TYPE_FO, APPLICANT_TYPE_FOP, APPLICANT_TYPE_PO, ATTORNEY_TYPE_FO, ATTORNEY_TYPE_FOP, ATTORNEY_TYPE_PO, PLNA_MOC_FIRST, PLNA_MOC_FOURTH, PLNA_MOC_THIRD } from '../constants/sharedConstants';
import PropTypes from 'prop-types';
import { resetApplicant, resetAttorney, resetForm } from '../helperFunctions/helpers';

const PersonTypeSelector = ({
    request,
    setRequest,
    decodedToken,
    keyToUpdate,
    setZadatelAttachments,
    setCurrentApplicant,
    setApplicantArr,
    parentKey = 'form',
}) => {
    const isMainLayout = keyToUpdate === 'main';

    const initiateReset = (prop, value, decodedToken = undefined) => {
        switch (prop) {
        case 'main': { 
            if (isMainLayout) resetForm(value, setRequest, setCurrentApplicant, setApplicantArr, decodedToken ?? null, parentKey);
            break;
        }
        case 'applicant': {
            resetApplicant(value, setRequest, decodedToken ?? null, parentKey);
            break;
        }
        case 'attorney': {
            resetAttorney(value, setRequest, decodedToken ?? null, parentKey);
            break;
        }
        default:
            console.log('invalid');
            break;
        }

        if (prop === 'main') {
            setZadatelAttachments(prev => {
                const resetObject = {};
                for (let key in prev) {
                    if (Object.hasOwn(prev, String(key))) {
                        resetObject[key] = [];
                    }
                }
                return resetObject;
            });
            return;
        }

        setZadatelAttachments(prev => ({...prev, 
            ...(prop === 'attorney' && {[PLNA_MOC_FOURTH]: []}),
            ...(prop === 'applicant' && {[PLNA_MOC_THIRD]: [], [PLNA_MOC_FIRST]: []}),
        }));
    };

    return (
        <>
            {isMainLayout ?
                <Row className='row-wrapper'>
                    <form className='d-flex flex-column p-0 form'>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input type='radio' name='stavebnik' value='me' className='radio' checked={request?.[parentKey]?.applicantMe ?? true}
                                onChange={(e) => initiateReset(keyToUpdate, e.target.value, decodedToken)} />
                            já
                        </label>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input type='radio' name='stavebnik' value='someone' className='radio' checked={request?.[parentKey]?.applicantAttorney ?? false}
                                onChange={(e) => initiateReset(keyToUpdate, e.target.value, decodedToken)} />
                            někdo jiný, zastoupený mnou
                        </label>
                        <label className='d-flex align-items-center label'>
                            <input type='radio' name='stavebnik' value='more' className='radio' checked={request?.[parentKey]?.applicantMore ?? false}
                                onChange={(e) => initiateReset(keyToUpdate, e.target.value, decodedToken)} />
                            více fyzických osob/podnikatelů/firem
                        </label>
                    </form>
                </Row>
                :
                <Row className='row-wrapper'>
                    <form className='d-flex flex-column p-0 form'>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input 
                                type='radio'
                                name='id'
                                value={keyToUpdate === 'applicant' ? APPLICANT_TYPE_FO : keyToUpdate === 'attorney' ? ATTORNEY_TYPE_FO : ''}
                                className='radio'
                                checked={request?.[parentKey]?.[keyToUpdate]?.isFO || (!request?.[parentKey]?.[keyToUpdate]?.isFO && !request?.[parentKey]?.[keyToUpdate]?.isFOBusiness && !request?.[parentKey]?.[keyToUpdate]?.isPO)
                                    || request?.[parentKey]?.[keyToUpdate] === null
                                }
                                onChange={(e) => initiateReset(keyToUpdate, e.target.value, decodedToken)}
                            />
                        fyzická osoba
                        </label>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input
                                type='radio'
                                name='id'
                                value={keyToUpdate === 'applicant' ? APPLICANT_TYPE_FOP : keyToUpdate === 'attorney' ? ATTORNEY_TYPE_FOP : ''}
                                className='radio'
                                checked={request?.[parentKey]?.[keyToUpdate]?.isFOBusiness}
                                onChange={(e) => initiateReset(keyToUpdate, e.target.value)}
                            />
                        fyzická osoba podnikající (pokud záměr souvisí s podnikatelskou činností)
                        </label>
                        <label className='d-flex align-items-center label'>
                            <input
                                type='radio'
                                name='id'
                                value={keyToUpdate === 'applicant' ? APPLICANT_TYPE_PO : keyToUpdate === 'attorney' ? ATTORNEY_TYPE_PO : ''}
                                className='radio'
                                checked={request?.[parentKey]?.[keyToUpdate]?.isPO}
                                onChange={(e) => initiateReset(keyToUpdate, e.target.value)} />
                        právnická osoba
                        </label>
                    </form>
                </Row>
            }
        </>
    );
};

PersonTypeSelector.propTypes = {
    request: PropTypes.object,
    setRequest: PropTypes.func,
    decodedToken: PropTypes.object,
    keyToUpdate: PropTypes.string,
    setZadatelAttachments: PropTypes.func,
    setCurrentApplicant: PropTypes.func,
    setApplicantArr: PropTypes.func,
};

export default PersonTypeSelector;