import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, ZamerSelectionControls } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form17.js';
import FormContext from '../../formContexts/FormContext.js';
import { isPruvodniListNotYetSubmitted, shouldNotSave, spreadParcelsAndConstructions, spreadObject, hasZamerError, setUploadedDocumentsSizeFromZamer, getOngoingUploads, areUserDetailsMatching } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from 'jwt-decode';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
};

const ZamerComponent17 = ({ urlPath, documents, setDocuments }) => {
    const {intention, stepValue, validationRef, setStagesArr, saveFormRef, id, setUploadedBytes, setWaitingForPruvodniList, setIsDetailsMatching} = useContext(FormContext); 

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);
    const ongoingUploads = React.useMemo(() => getOngoingUploads(Object.values(documents || {})?.flat()), [documents]);
    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);

    const decodedToken = jwtDecode(token);
    const detailsMatching = React.useMemo(() => areUserDetailsMatching(decodedToken, request?.form), [decodedToken, request?.form]);

    useEffect(() => {
        setIsDetailsMatching(detailsMatching);
    }, [detailsMatching]);

    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        buildConstructions: [],
        buildParcels: [],
    });

    const buildIntention = request?.buildIntention;

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (buildIntention?.address?.zip) {
                const cleanedValue = buildIntention?.address?.zip.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }
    
            if (hasZamerError(buildIntention)) {
                err = true;
            } 

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);
    
                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { id: formData.id, ...formData } } : {}) }));       
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value,
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    const loadedBuildApplication = response?.data?.buildApplication || {};
                    const documentations = loadedBuildApplication?.documentations ?? null;
                    if (documentations && !ongoingUploads?.length) setUploadedDocumentsSizeFromZamer(documentations, setUploadedBytes);
                    if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(response?.data ?? {}));
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (
            (buildIntention?.buildParcels) ||
            (buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(buildIntention?.buildParcels ?? [])].map((item) => ({...item, uid: uuidv4()})),
                buildConstructions: [...(buildIntention?.buildConstructions ?? [])].map((item) => ({...item, uid: uuidv4()})),
            }));
            setRequest(prev => ({...prev, buildConstructions: buildIntention?.buildConstructions, buildParcels: buildIntention?.buildParcels}));
        }

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        setParcelAndConstructionData={setParcelAndConstructionData}
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                        documents={documents}
                        setDocuments={setDocuments}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper border rounded p-4'>
                        <p className='section-title p-0 mb-4'>Místo záměru (nepovinné)</p>
                        <form className='p-0'>

                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    <Row className='row-wrapper'>
                        <PozemkyTable
                            subtitle={'na kterých se má záměr uskutečnit'}

                            urlPath={urlPath}
                            dataToAdd={parcelAndConstructionData.buildParcels}
                            setDataToAdd={setParcelAndConstructionData}
                        />
                        <StavbyTable
                            title={'Stavby'}
                            subtitle={'na kterých se má záměr uskutečnit'}

                            urlPath={urlPath}
                            dataToAdd={parcelAndConstructionData.buildConstructions}
                            setDataToAdd={setParcelAndConstructionData}
                        />
                    </Row>

                </Container>}
        </>
    );
};

ZamerComponent17.propTypes = {

    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent17;