import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import '../../css/zadostiDetail.css';
import '../../css/reusable.css';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { GovButton, GovFormInput } from '@gov-design-system-ce/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import authConfig from '../../config/authorization-config';
// import {  formatUrlWithToken } from '../../helperFunctions/helpers.js';
import { getDocumentationToken } from '../../apiCalls/componentsApiCalls.js';
import '../../css/dokumentace.css';

const DokumentaceDetailHeaderSection = ({ item, owner = false }) => {
    const { token } = useContext(AuthContext);
    const [showCopySuccess, setShowCopySuccess] = useState(false);
    const { token: documentationToken } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location || {};
    const versionId = item?.versionId;
    console.log(versionId);
    const newVersionUrl = versionId ? `/dokumentace/version/${versionId}${documentationToken ? `/${documentationToken}` : ''}` : '#';
    const rootUrl = authConfig?.redirectUri ?? '';
    const shareUrl = `${rootUrl?.substring(0, rootUrl.length - 1) ?? ''}${pathname ?? ''}` ?? '';

    useEffect(() => {
        if (showCopySuccess) {
            const timer = setTimeout(() => {
                setShowCopySuccess(false);
            }, 60000);

            return () => clearTimeout(timer);
        }
    }, [showCopySuccess]);

    const getToken = async (item) => {  
        try {
            const source = axios.CancelToken.source();

            const data = {
                'bppId': item?.bppId,
                'filePid': item?.filePid,
                'iscId': item?.projectId,
            };
            const documentationToken = await getDocumentationToken(data, token, source);
            return documentationToken;
        } catch (err) {
            console.log('Error getting token', err);
        }
    };

    return (
        <div className='my-4'>
            <Row className='base-width d-flex pt-4 pb-5 border-bottom'>
                <Col xs={12} md={6} lg={8}>
                    <div className='d-flex flex-column gap-4 my-5'>
                        <h1 className='header__detail-id-big mb-0'>{`Název záměru: ${item?.projectName ?? ''}`}</h1>
                        <h3 className='header__detail-project'>{`Číslo Záměru: ${item?.projectId ?? ''}`}</h3>
                    </div>
                </Col>

                {owner && <Col xs={12} md={6} lg={4} className='d-flex flex-column p-4 gap-3 bg-white border'>
                    <div className='border-bottom pb-3'>
                        <div className='gov-btn-container'>
                            <GovButton
                                variant="primary"
                                type="solid" 
                                size="m"
                                onGov-click={() => navigate(newVersionUrl)}
                            >
                                Vložit novou verzi
                            </GovButton>
                        </div>
                    </div>

                    <div className='d-flex flex-column gap-2'>
                        <GovFormInput
                            disabled
                            value={shareUrl?.trim()}
                        />

                        <div className='gov-btn-container'>
                            <GovButton
                                type='outlined'
                                variant='primary'
                                size='m'
                                onGov-click={async () => {
                                    try {
                                        if (!shareUrl?.trim()) {
                                            return;
                                        }
                                        const docuToken = await getToken(item);
                                        const docuUrl = shareUrl.length < 85 ? `${shareUrl}/${docuToken || ''}` : shareUrl;
                                        await navigator.clipboard.writeText(docuUrl?.trim());
                                        setShowCopySuccess(true);
                                    } catch (err) {
                                        setShowCopySuccess(false);
                                    }
                                }}
                            >
                                Kopírovat odkaz pro sdílení
                            </GovButton>

                            {showCopySuccess && (
                                <p className='gov-color--success-400 gov-text--s text-center pt-2' style={{textWrap: 'nowrap'}}>Odkaz úspěšně zkopírován do schránky!</p>
                            )}
                        </div>

                        <div className='pt-2'>
                            <p className='p-0 dokumentace-instruction_text'>
                            Pomocí odkazu pro sdílení můžete poskytnout přístup k Vámi vložené dokumentaci jinému uživateli portálu stavebníka (např. projektantovi, jinému stavebníkovi či zástupci)
                            </p>
                        </div>
                    </div>
                </Col>}
            </Row>
        </div>
    );

};

DokumentaceDetailHeaderSection.propTypes = {
    item: PropTypes.any,
    owner:PropTypes.bool,
};

export default DokumentaceDetailHeaderSection;
