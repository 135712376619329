import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, NewConstructionModal, AffectedConstructionsAndParcels, ZamerSelectionControls, VodniDilo } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form08.js';
import FormContext from '../../formContexts/FormContext.js';
import { handleClose, handleShow } from '../../helperFunctions/modalHelpers';
import { isPruvodniListNotYetSubmitted, shouldNotSave, spreadParcelsAndConstructions, spreadObject, checkLimitAndInsertText, hasZamerError, setUploadedDocumentsSizeFromZamer, getOngoingUploads, areUserDetailsMatching, } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { CharCount } from '../../components/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from 'jwt-decode';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    newBuilding: false,
    buildingTypePurpose: '',
    changeOfFinishedBuilding: false,
    reasonChangeOfBuildingUse: false,
    newBuildingUse: '',
    buildingTemporary: false,
    setOfBuildings: false,
    techOrMarketFacility: false,
    siteFacility: false,
    waterworks: false,
    buildingTemporaryDuration: '',
    parcelChangeSuggestion: '',
    waterworksPurpose: '',
    watercourseName: '',
    watercourseId: '',
    hydrogeologicalAreaName: '',
    hydrogeologicalAreaID: '',
    documentationId: '',
    useNeighborFacilityReason: '',
    useNeighborFacility: false,
};

const ZamerComponent08 = ({
    documents,
    setDocuments,
    urlPath,
    setCheckbox
}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id, setUploadedBytes, setWaitingForPruvodniList, setIsDetailsMatching} = useContext(FormContext); 
    const [show, setShow] = useState(false);

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);
    const ongoingUploads = React.useMemo(() => getOngoingUploads(Object.values(documents || {})?.flat()), [documents]);
    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);

    const decodedToken = jwtDecode(token);
    const detailsMatching = React.useMemo(() => areUserDetailsMatching(decodedToken, request?.form), [decodedToken, request?.form]);

    useEffect(() => {
        setIsDetailsMatching(detailsMatching);
    }, [detailsMatching]);


    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [newBuilding, setNewBuilding] = useState({
        isChecked: false,
        select: '',
    });
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 1000},
    });
    const [ focused, setFocused ] = useState({
        newBuildingUse: false,
        buildingTemporaryDuration: false,
        parcelChangeSuggestion: false,
        waterworksPurpose: false,
        watercourseName: false,
        watercourseId: false,
    });   
    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        affectedBuildConstructions: [],
        affectedBuildParcels: [],
        buildConstructions: [],
        buildParcels: [],
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);
    
                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    if (formData && formData.id) {
                        setCheckbox({
                            first: formData?.applicationExceptionRequirementBuild ?? false,
                        });
                    }

                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest), ...((formData && formData.id) ? { form: { ...formData } } : {}) }));
                        
                        setNewBuilding(state => ({ ...state, isChecked: intention.newBuilding, select: intention.buildingTypePurpose }));
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value,
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    const loadedBuildApplication = response?.data?.buildApplication || {};
                    const documentations = loadedBuildApplication?.documentations ?? null;
                    if (documentations && !ongoingUploads?.length) setUploadedDocumentsSizeFromZamer(documentations, setUploadedBytes);
                    if (setWaitingForPruvodniList) setWaitingForPruvodniList(isPruvodniListNotYetSubmitted(response?.data ?? {}));
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };
    
    const buildIntention = request?.buildIntention;
    
    useEffect(() => {
        if (
            (buildIntention?.buildParcels) ||
            (buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(buildIntention?.buildParcels ?? [])].map((item) => ({...item, uid: uuidv4()})),
                buildConstructions: [...(buildIntention?.buildConstructions ?? [])].map((item) => ({...item, uid: uuidv4()})),
            }));
            setRequest(prev => ({...prev, buildConstructions: buildIntention?.buildConstructions, buildParcels: buildIntention?.buildParcels}));
        }
        
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            } else if (buildIntention?.newBuilding && !buildIntention?.buildingTypePurpose.trim()) {
                err = true;
            }
            else if (buildIntention?.buildingTemporary &&
                (!buildIntention?.buildingTemporaryDuration?.trim() || !buildIntention?.parcelChangeSuggestion?.trim()))
            {
                err = true;
            }
            else if(buildIntention?.reasonChangeOfBuildingUse && !buildIntention?.newBuildingUse?.trim())
            {
                err = true;
            }
            else if (buildIntention?.waterworks && !buildIntention?.waterworksPurpose)
            {
                err = true;
            }

            if (buildIntention?.address?.zip) {
                const cleanedValue = buildIntention?.address?.zip.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        setNewBuilding={setNewBuilding}
                        setParcelAndConstructionData={setParcelAndConstructionData}
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                        documents={documents}
                        setDocuments={setDocuments}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper'>
                        <p className='section-title p-0 mb-4'>Místo záměru</p>
                        <form className='p-0'>
                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    {/* Charakteristika záměru */}
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-1 section-title'>Charakteristika záměru</p>
                        <p className='p-0 mb-4 section-description'>v případě, že se žádost o vyjádření týká stavby</p>
                        <form className='d-flex flex-column p-0'>
                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={newBuilding.isChecked ?? false}
                                    onChange={(e) => { setNewBuilding(state => ({ ...state, isChecked: e.target.checked })); setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, newBuilding: e.target.checked } })); }} />
                                Nová stavba
                            </label>
                            {/* {request.buildIntention.newBuilding && <Row className='border p-4 row-wrapper'>
                                <label className='p-0 d-flex flex-column select-input'>
                                    Druh a účel stavby
                                    <select
                                        type='select'
                                        value={newBuilding?.select ?? ''}
                                        className='mt-1 select'
                                        onChange={(e) => { setNewBuilding(state => ({ ...state, select: e.target.value })); setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTypePurpose: e.target.value } })); }}
                                    >
                                        <option value=''>vyberte</option>
                                        <option value={'1'}>1</option>
                                        <option value={'2'}>2</option>
                                        <option value={'3'}>3</option>
                                    </select>
                                </label>
                            </Row>} */}
                            {newBuilding.isChecked &&
                            <Row className='row-wrapper'>
                                <p className='p-0 mt-3 mb-1 new-construction-help-text' style={{color: error && !buildIntention?.buildingTypePurpose.trim() ? '#C52A3A' : ''}}>Druh a účel stavby</p>
                                <Col md={6} sm={12}>
                                    <label className='d-flex p-0 id-checkbox-label w-100'>
                                        <input
                                            type="text"
                                            value={newBuilding?.select ?? ''}
                                            className='new-construction-input'
                                            disabled
                                        />
                                        <button
                                            type='button'
                                            className='d-flex justify-content-center align-items-center new-construction-button'
                                            style={{border: error && !buildIntention?.buildingTypePurpose.trim() ? 'solid 1px #C52A3A' : ''}}
                                            onClick={() => handleShow(setShow)}
                                        >
                                            Vybrat
                                        </button>
                                    </label>
                                    <NewConstructionModal 
                                        show={show} 
                                        setShow={setShow}
                                        handleClose={handleClose}
                                        setNewBuilding={setNewBuilding} 
                                        setRequest={setRequest}
                                    />
                                </Col>
                                <Col md={6} sm={12}>
                                    <p className='building-change my-2 my-md-0 ps-0 ps-md-3'>V případě souboru staveb uveďte stavbu hlavní</p>
                                </Col>
                            </Row>}
                            <Col md={6} sm={12}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.changeOfFinishedBuilding)}
                                        onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, changeOfFinishedBuilding: e.target.checked } })); }} />
                                    Změna dokončené stavby (nástavba, přístavba, stavební úprava)
                                </label>
                            </Col>

                            {
                                buildIntention.changeOfFinishedBuilding &&
                                <Row className='row-wrapper'>
                                    <Row className='mb-2'>
                                        <Col md={6} sm={12}>
                                            <label className='pe-3 id-checkbox-label'>
                                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.reasonChangeOfBuildingUse)}
                                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, reasonChangeOfBuildingUse: e.target.checked } })); }} />
                                                Navrhuje se z důvodu změny ve způsobu užívání stavby
                                            </label>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <p className='building-change my-2 my-md-0 ps-0 ps-md-3'>Uveďte, zda se nástavba, přístavba nebo stavební úprava se navrhuje z důvodu změny ve způsobu užívání stavby (změna ve způsobu užívání stavby, změna v provozním zařízení stavby, změna ve způsobu výroby nebo podstatném rozšíření výroby, změna v činnosti, jejíž účinky by mohly ohrozit život nebo veřejné zdraví, život a zdraví zvířat, bezpečnost nebo životní prostředí).</p>
                                        </Col>
                                    </Row>
                                    {buildIntention.reasonChangeOfBuildingUse && 
                                    <Row className='d-flex pb-4'>
                                        <Col xs={12} md={6} className='d-flex flex-column'>
                                            <label className='select-input' style={{color: (!buildIntention?.newBuildingUse?.trim() && (error || focused.newBuildingUse)) ? '#C52A3A' : ''}}>
                                            Nový způsob užívání stavby
                                                <input
                                                    type='text'
                                                    {...(focused.newBuildingUse && { focused: 'true' })}
                                                    defaultValue={buildIntention.newBuildingUse ?? ''}
                                                    style={{border: error && !buildIntention?.newBuildingUse?.trim() ? 'solid 1px #C52A3A' : ''}}
                                                    required
                                                    onBlur={(e) => {
                                                        setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, newBuildingUse: e.target.value } }));
                                                        handleFocused('newBuildingUse');
                                                    }}
                                                    className='mt-1 id-input2 waterworks bg-white p-2'
                                                />
                                            </label>
                                        </Col>
                                    </Row>}
                                </Row>
                            }

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.buildingTemporary)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporary: e.target.checked } })); }} />
                                Stavba dočasná
                            </label>
                            {buildIntention.buildingTemporary && <Row className='border p-4 row-wrapper'>
                                <Row className='d-flex pb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input' style={{color: (!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.buildingTemporaryDuration)) ? '#C52A3A' : ''}}>
                                        Doba trvání dočasné stavby
                                            <input
                                                type='text'
                                                {...(focused.buildingTemporaryDuration && { focused: 'true' })}
                                                defaultValue={buildIntention.buildingTemporaryDuration ?? ''}
                                                style={{border: error && !buildIntention?.buildingTemporaryDuration?.trim() ? 'solid 1px #C52A3A' : ''}}
                                                required
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporaryDuration: e.target.value } }));
                                                    handleFocused('buildingTemporaryDuration');
                                                }}
                                                className='mt-1 id-input2 waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <label className='d-flex flex-column p-0 mt-4 textarea-input' style={{ color: error && !buildIntention?.parcelChangeSuggestion?.trim() ? '#C52A3A' : '' }}>
                                    Návrh úpravy pozemku po jejím odstranění
                                    <Row className='position-relative textarea-with-count-container'>
                                        <textarea
                                            className='textarea-with-count mt-2 p-2' 
                                            rows="5" 
                                            defaultValue={buildIntention.parcelChangeSuggestion ?? ''}
                                            style={{ border: error && !buildIntention?.parcelChangeSuggestion?.trim() ? 'solid 1px #C52A3A' : '' }}
                                            onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}                
                                            onBlur={(e) => setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, parcelChangeSuggestion: e.target.value } }))}
                                        />
                                        <CharCount 
                                            value={buildIntention.parcelChangeSuggestion} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                                        />
                                    </Row>
                                </label>
                            </Row>}

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.setOfBuildings)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, setOfBuildings: e.target.checked } })); }} />
                                Soubor staveb
                            </label>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.techOrMarketFacility)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, techOrMarketFacility: e.target.checked } })); }} />
                                Technické zařízení nebo reklamní zařízení
                            </label>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.siteFacility)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, siteFacility: e.target.checked } })); }} />
                                Zařízení staveniště
                            </label>
                            <Col md={6}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.waterworks)}
                                        onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworks: e.target.checked } })); }} />
                                    Vodní dílo
                                </label>
                            </Col>
                            {buildIntention.waterworks &&
                            <VodniDilo
                                buildIntention={buildIntention}
                                focused={focused}
                                request={request}
                                setRequest={setRequest}
                                handleFocused={handleFocused}
                            />}
                        </form>
                    </Row>

                    {buildIntention.useNeighborFacility && 
                    <AffectedConstructionsAndParcels
                        setDataToAdd={setParcelAndConstructionData}
                        isAffected={true}
                        
                        urlPath={urlPath}
                        setRequest={setRequest}
                        affectedBuildParcels={parcelAndConstructionData.affectedBuildParcels}
                        affectedBuildConstructions={parcelAndConstructionData.affectedBuildConstructions}   
                    />}

                    <PozemkyTable
                        subtitle={'na kterých se má záměr uskutečnit'}
                        
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildParcels}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                    <StavbyTable
                        title={'Stavby'}
                        subtitle={'na kterých se má záměr uskutečnit'}
                        
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildConstructions}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                </Container>}
        </>
    );
};

ZamerComponent08.propTypes = {
    

    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent08;