import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Dokumentace, ZamerControlsMinimal } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { jwtDecode } from 'jwt-decode';
import { isPrevDocumentationExisting } from '../../apiCalls/componentsApiCalls.js';

const NovaDokumentaceMainSection = ({
    documents,
    setDocuments,
    urlPath,
    isPlEmptyForUrednik = false,
    isDocumentationWithId = false,
}) => {
    const buildIntentionModel = {
        title: { 
            value: null, 
            dirty: false 
        },
        accompanyingDocumentPdf: null,
        accompanyingDocumentDeveloper: null,
    };
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState({
        buildIntention: buildIntentionModel,
        'buildApplication': {
            'documentations': [],
            shareForm: {
                firstName: '',
                lastName: '',
                paperType: 'ID',
                paperNumber: ''
            },
            authorizedPersonSharedLink: ''
        },
        'accompanyingDocument': {
            accompanyingDocumentStatus: '' 
        },
    });
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const [fetching, setFetching] = useState(true);
    const decodedToken = jwtDecode(token);
    const intentionBppPid = decodedToken?.intentionBppPid ?? '';
    const [canShowDocumentationRadioButtons, setCanShowDocumentationRadioButtons] = useState(false); 
    
    useEffect(() => {
        isPrevDocumentationExisting(intentionBppPid, token, setCanShowDocumentationRadioButtons, setFetching);
    }, []);
    
    return (
        <>
            {!fetching &&
            <>
                <ZamerControlsMinimal 
                    urlPath={urlPath}
                    title={'Číslo a název záměru'}
                    isDocumentationWithId={isDocumentationWithId}
                    request={request}
                    setRequest={setRequest}
                    state={state}
                    setState={setState}
                    buildIntentionModel={buildIntentionModel}
                />
                <Dokumentace
                    isPlEmptyForUrednik={isPlEmptyForUrednik}
                    urlPath={urlPath}
                    documents={documents}
                    setDocuments={setDocuments}
                    documentationFormRequest={request}
                    canShowDocumentationRadioButtons={canShowDocumentationRadioButtons}
                />
            </>}
        </>
    );
};

NovaDokumentaceMainSection.propTypes = {

    documents: PropTypes.object,
    setDocuments: PropTypes.func,
    isDocumentationWithId: PropTypes.any,
    urlPath: PropTypes.any,
};

export default NovaDokumentaceMainSection;