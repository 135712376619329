import React from 'react';
import { Button } from './index.js';
import { stop } from '../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProcessError = ({ idFromBoundary = null, isFromErrorBoundary = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname, id } = location?.state || {};
    const { pathname: currentFormPathName } = location || {};
    
    const handleNavigate = () => {
        if (isFromErrorBoundary) {
            const currentPathWithId = /^\/zadost\/form[0-9]{2}\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.exec(currentFormPathName)?.[0] ?? '';
            const currentPathWithoutId = /^\/zadost\/form[0-9]{2}$/.exec(currentFormPathName)?.[0] ?? '';
            const isExternalDocumentation = idFromBoundary === 'external_documentation';
            if (currentPathWithId || isExternalDocumentation) {
                window.location.reload(); 
            } else if (currentPathWithoutId) {
                if (idFromBoundary) {
                    navigate(`${currentPathWithoutId}/${idFromBoundary}`);
                } else {
                    navigate(`${currentPathWithoutId}`);
                }
            } else {
                navigate('/');
            }
            return;
        }

        if (!id || !pathname) {
            navigate('/');
            return;
        }

        const urlHasId = pathname?.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g);

        if (Array.isArray(urlHasId) && urlHasId[0] === id) {
            navigate(`${pathname}`);
        } else {
            navigate(`${pathname}/${id}`);
        }
    };

    return(
        <div className='card-content card vh-100 d-flex justify-content-center align-items-center p-3 mt-5'>
            <img className='error-page--img' src={stop} alt='something went wrong'/>
            <p className='error-page--header-text'>Chyba aplikace</p>
            <p className='error-page--secondary-text no-max-width no-margin'>
            Omlouváme se, v aplikaci se vyskytla chyba která zabraňuje dalšímu používání. <b>Nebojte se, žádosti ukládáme průběžně. </b>
            </p>
            <p className='error-page--secondary-text no-margin'>
            Je možné, že problém způsobilo některé z rozšíření ve vašem prohlížeči.
            </p>
            <p className='error-page--secondary-text no-margin'>
            Příčinou problému také mohou být automatické překlady stránek, které mohou způsobit chybné načtení stránky.
            </p>
            <p className='error-page--secondary-text no-margin'>
            V Google Chrome je možné automatické překlady vypnout v nastavení prohlížeče - Jazyky - Vypnout Google Translate.
            </p>
            <p className='error-page--secondary-text no-margin'>
            Prosím zkuste se do portálu přihlásit v anonymním okně, kde jsou rozšíření zpravidla vypnutá.
            </p>
            <p className='error-page--secondary-text'>
            Můžete též zkusit obnovit stránku.
            </p>
            <div className='d-flex justify-content-center mb-2'>
                <Button 
                    onClickFunction={handleNavigate} 
                    buttonLabel={'Obnovit stránku'}
                />
            </div>
            <i>Error 500</i>
        </div>
    );
};

ProcessError.propTypes = {
    idFromBoundary: PropTypes.string,
    isFromErrorBoundary: PropTypes.bool
};

export default ProcessError;