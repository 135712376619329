import axios from 'axios';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { iconChange } from '../../../assets';
import {
    CADASTRAL_AREA, DESCRIPTIVE_NUMBER, ICS, PARCEL_NUMBER, REGISTRATION_NUMBER, TYPE_OF_USE, UNIT_NUMBER, AFFECTED_STAVBY_TABLE, APPROVED_STAVBY_TABLE, STAVBY_TABLE, STAVBY_MAPPING, STAVBY_MAPPING_CISELNIKY, PURPOSE_OF_USE, TYPE_OF_USAGE, TEMPORARY_BUILDING,
} from '../../../constants/sharedConstants.js';
import { trashbin } from '../../../assets';
import { Container } from 'react-bootstrap';
import '../../../css/formTableView.css';
import { ButtonsAndPaginate } from '../../../components/index.js';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { enterCsvData, getAnoNeValue, getFormType, isExceeding, removeCsvData, setCsvDataAndRequest, shouldNotSaveTable } from '../../../helperFunctions/helpers.js';
import { formSave } from '../../../apiCalls/formApiCalls.js';
import FormContext from '../../../formContexts/FormContext.js';

const StavbyTable = ({

    urlPath,
    title,
    subtitle,
    isAffected,
    dataToAdd,
    approvedConstructions, // bool | undefined
    receivedToken,
    setIsExceeded,
    setDataToAdd
}) => {
    const [showStavbaModal, setShowStavbaModal] = useState(false);
    const resultPerPage = 25;
    const [listData, setListData] = useState([]);
    const { token } = useContext(AuthContext);
    const [startIndex, setStartIndex] = useState(0);
    const endIndex = startIndex + resultPerPage;
    const {intention, id} = useContext(FormContext); 
    const [tableRequest, setTableRequest] = useState({
        'applicationId': id,
    });
    const currentPageRef = useRef(null);
    const [currentlyShowing, setCurrentlyShowing] = useState(0);
    let saveCall = Promise.resolve();
    const dynamicKey = isAffected ? 'affectedBuildConstructions' : approvedConstructions ? 'approvedConstructions' : 'buildConstructions';
    const preloadRef = useRef(false);
    const [itemToUpdate, setItemToUpdate] = useState(null);
    const formType = getFormType(urlPath);
    const isForm19 = formType === 19;
    const is15NotAff = formType === 15 && !isAffected;
    const is19NotAff = (isForm19 && !isAffected);
    const isForm18 = Boolean(receivedToken); // for F18 url format is different. getformtype is not for F18.
    const propertyToUpdate = isAffected ? 'affectedBuildConstructions' : 'buildConstructions';
    
    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        saveCall = saveCall.then(() => formSave(tableRequest, token, urlPath, source, receivedToken ?? undefined));
        try {
            await saveCall;
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (isExceeding(intention, listData.length, 'forWarning')) {
            if(setIsExceeded) setIsExceeded(true);
        } else {
            if(setIsExceeded) setIsExceeded(false);
        }
    }, [listData]);

    useEffect(() => {
        if (!dataToAdd) {
            return;
        }

        if (dataToAdd.length > 0) {
            preloadRef.current = true;
        }

        setCsvDataAndRequest(
            listData,
            setListData,
            setTableRequest,
            dynamicKey,
            dataToAdd
        );

    }, [dataToAdd]);
    
    useEffect(() => {
        if (shouldNotSaveTable(tableRequest)) {
            return;
        }

        if (preloadRef.current) {
            preloadRef.current = false;
            return;
        }

        if (setDataToAdd) setDataToAdd(prev => ({...prev, [propertyToUpdate]: [...listData]}));

        saveChanges();
    }, [tableRequest]);

    const handleRemove = (keyStr) => {
        const newData = listData.filter(item => String(item.uid) !== String(keyStr));
        setListData(newData);

        removeCsvData(setTableRequest, newData, dynamicKey);
    };

    const handleUpdate = (data) => {
        setItemToUpdate(data);
        setShowStavbaModal(true);
    };

    const trimValue = (value) => value?.trim() ?? '';

    const handleCsv = async (data) => {
        const newLines = [];

        await data.forEach((line, index, self) => {
            try {
                let newLine = {};
                
                if (index === 0) {
                    return;
                }
    
                const headers = self[0];
                
                for (let i = 0; i < line.length; i++) {
                    const item = line[i];
                    const itemName = headers[i]?.trim()?.toLowerCase();
                    const itemValue = trimValue(item);

                    if (/^(ano|ne)$/i.test(itemValue) && /\(ano\/ne\)/.test(itemName)) {
                        newLine[STAVBY_MAPPING[itemName]] = getAnoNeValue(itemValue);
                    } else {
                        const itemData = STAVBY_MAPPING_CISELNIKY[itemName];
                        if (Array.isArray(itemData) && i.code) {
                            const itemId = itemValue.includes(' ') ? itemValue.split(' ')[0] : '1';
                            const itemDataSelected = itemData.filter(i => i.code === itemId)[0];

                            newLine[STAVBY_MAPPING[itemName]] = itemDataSelected.code + ' ' + itemDataSelected.name;
                        } else {
                            newLine[STAVBY_MAPPING[itemName]] = itemValue;
                        }
                    }
                }
    
                newLine.dirty = false;
                newLine.imported = true;
    
                newLines.push(newLine);
            } catch (error) {
                console.log(error);
            }
        });

        setListData([...newLines.map(el => ({
            ...el,
            uid: uuidv4(),
            imported: true
        }))]);

        enterCsvData(setTableRequest, newLines, dynamicKey);
    };

    useEffect(() => {
        if (listData.length === 0 || !currentPageRef.current) {
            return;
        }

        setCurrentlyShowing(currentPageRef.current.childNodes.length);
    }, [listData.length, startIndex]);

    return (
        <Container className='' fluid>
            {/* <h2 className='m-0 pb-2'>{isFrom18 ? 'Stavby a jejich parametry' : isAffected ? 'Sousední stavby' : 'Stavby'}</h2> */}
            <h2 className='m-0 pb-2'>{title}</h2>
            {subtitle && <p className='p-0 mb-4 table-subtitle'>{subtitle}</p>}
            <Container className='overflow-x-scroll' fluid>
                {(listData.length > 0) &&
                <>
                    <table className='border my-3' >
                        <thead>
                            <tr>
                                <th></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CADASTRAL_AREA}`}</p></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${PARCEL_NUMBER}`}</p></th>
                                {(formType === 1 || formType === 2 || formType === 4 || is19NotAff) && 
                                <th className='py-2 px-3'>
                                    <p className='table-head py-2 d-flex'>
                                        {`${DESCRIPTIVE_NUMBER}/${UNIT_NUMBER.charAt(0).toLowerCase() + UNIT_NUMBER.slice(1)}`}
                                    </p>
                                </th>}
                                {(formType !== 1 && formType !== 2 && formType !== 4 && !is19NotAff) && 
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${DESCRIPTIVE_NUMBER}`}</p></th>}
                                {(formType === 9 || formType === 8) &&
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${UNIT_NUMBER}`}</p></th>}
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${REGISTRATION_NUMBER}`}</p></th>
                                {(formType === 10 || formType === 14) && <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${TYPE_OF_USAGE}`}</p></th>}
                                {(formType === 10 || formType === 14) && <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${PURPOSE_OF_USE}`}</p></th>}
                                {(formType !== 10 && formType !== 11 && formType !== 12 && formType !== 13 && formType!==14) && <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${TYPE_OF_USE}`}</p></th>}
                                {(formType !== 9 && formType !== 1 && formType !== 2 && formType !== 8 && formType !== 4 && formType !== 10 && formType !== 14 && !isForm18 && formType !== 11 && formType !== 12 && formType !== 13 && !is15NotAff && !is19NotAff) &&
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${UNIT_NUMBER}`}</p></th>}
                                {((formType === 16 && !isAffected) || (is15NotAff) || (formType !== 16 && formType !== 15 && is19NotAff) || (formType === 10) || (formType === 4 && !isAffected) || formType === 1 || formType === 2 || formType === 8 || (formType === 9 && !isAffected) || formType === 17 || formType === 14) && <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${ICS}`}</p></th>}
                                {(formType === 14) && <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${TEMPORARY_BUILDING}`}</p></th>}
                                <th className='py-2 px-3'></th>
                            </tr>
                        </thead>
                        <tbody ref={currentPageRef}>
                            {listData.length > 0 && listData.map((data, index, array) => {
                                if ((array.length <= resultPerPage) || (index >= startIndex && index < endIndex)) {
                                    return (
                                        <tr key={data.uid}>
                                            <td className='py-2 px-3'><p className='row-number'>{index + 1}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.cadastralTerritory ?? ''}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.parcelNumber ?? ''}</p></td>
                                            {(formType === 1 || formType === 2 || formType === 4 || is19NotAff) && 
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionNumber ?? ''}{(data?.constructionNumber && data?.flatNumber) ? '/' : ''}{data?.flatNumber ?? ''}</p></td>}
                                            {(formType !== 1 && formType !== 2 && formType !== 4 && !is19NotAff) && 
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionNumber ?? ''}</p></td>}
                                            {(formType === 9 || formType === 8) &&
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.flatNumber ?? ''}</p></td>}
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.registrationNumber ?? ''}</p></td>
                                            {(formType === 10|| formType === 14) && <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionType ?? ''}</p></td>}
                                            {(formType === 10|| formType === 14) && <td className='py-2 px-3'><p className='row-info py-2'>{data?.usePurpose ?? ''}</p></td>}
                                            {(formType !== 10 && formType !== 11 && formType !== 12 && formType !== 13 && formType!==14) && <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionPurpose ?? ''}</p></td>}
                                            {(formType !== 9 && formType !== 1 && formType !== 2 && formType !== 8 && formType !== 4 && formType !== 10 && formType !== 14 && !isForm18 && formType !== 11 && formType !== 12 && formType !== 13 && !is15NotAff && !is19NotAff) && 
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.flatNumber ?? ''}</p></td>}
                                            {((formType === 16 && !isAffected) || (is15NotAff) || (formType !== 16 && formType !== 15 && is19NotAff) || (formType === 10) || (formType === 4 && !isAffected)  || formType === 1 || formType === 2 || formType === 8 || (formType === 9 && !isAffected) || formType === 17 || formType === 14) && <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionId ?? ''}</p></td>}
                                            {(formType === 14) && <th className='py-2 px-3'><p className='row-info py-2'>{(data?.temporary && 'Ano')||'Ne'}</p></th>}
                                            <td className='py-2 px-3'>
                                                <button 
                                                    type='button'
                                                    onClick={() => handleUpdate(data)}
                                                    className='d-flex justify-content-center align-items-center table-button-upravit'
                                                >
                                                    <img src={iconChange} alt='upravit' className='pe-2' />
                                                    {data?.imported ? 'Náhled' : 'Upravit'}
                                                </button>
                                            </td>
                                            <td className='py-2 px-3'>
                                                <button
                                                    type='button'
                                                    onClick={() => handleRemove(data.uid)}
                                                    className='d-flex justify-content-center align-items-center table-button-smazat'
                                                >
                                                    <img src={trashbin} alt='trashbin' className='pe-2' />
                                                        Smazat
                                                </button>
                                            </td>
                                        </tr>);
                                }
                            })}
                        </tbody>
                    </table>
                </>
                  
                }
            </Container>
            <ButtonsAndPaginate
                showStavbaModal={showStavbaModal}
                setShowStavbaModal={setShowStavbaModal}
                itemToUpdate={itemToUpdate}
                setItemToUpdate={setItemToUpdate}
                category={isAffected ? AFFECTED_STAVBY_TABLE : approvedConstructions ? APPROVED_STAVBY_TABLE : STAVBY_TABLE}
                listData={listData}
                setListData={setListData}
                startIndex={startIndex}
                setStartIndex={setStartIndex}
                    
                handleCsv={handleCsv}
                setTableRequest={setTableRequest}
                resultPerPage={resultPerPage}
                currentlyShowing={currentlyShowing}
                isAffected={isAffected}
            />
        </Container>
    );
};

StavbyTable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.any,

    urlPath: PropTypes.string,
    isAffected: PropTypes.bool,
    dataToAdd: PropTypes.arrayOf(Object),
    setDataToAdd: PropTypes.func,
    affectedBuildConstructions: PropTypes.arrayOf(Object),
    approvedConstructions: PropTypes.any,
    receivedToken: PropTypes.string,
    setIsExceeded: PropTypes.func,
    setNewBuilding: PropTypes.func,
    newBuilding: PropTypes.any,
};

export default StavbyTable;
