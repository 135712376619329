import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import { infoCircle, warningUnfilled } from '../assets';

const CharCount = ({count, limit, setCount, propToUpdate, value, allow = false}) => {
    
    useEffect(() => {
        if (!value) {
            return;
        }

        if (count === 0 && value?.length > 0) {
            setCount(prev => ({ ...prev, [propToUpdate]: { ...prev[propToUpdate], count: value.length } }));
        }
    }, [value]);

    useEffect(() => {
        if (!allow) {
            return;
        }

        if (!document.querySelector('.text-area-appendix--info') && (limit === 10000 && count >= 1000)) {            
            const parent = document.querySelector('.textarea-with-count-container');
            const div = document.createElement('div');
            div.className = 'row text-area-appendix--info';
            const p = document.createElement('p');
            const img = document.createElement('img');
            p.className = 'd-flex align-items-center p-0 kontrola-info my-1';
            p.textContent = 'Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.';
            img.setAttribute('src', infoCircle); 
            img.setAttribute('alt', 'info'); 
            img.className = 'me-2';
            p.insertAdjacentElement('afterbegin', img);
            div.appendChild(p);
            parent.insertAdjacentElement('afterend', div);
        } else if (document.querySelector('.text-area-appendix--info') && (limit === 10000 && count < 1000)) {
            document.querySelector('.text-area-appendix--info').remove();
        }

        if (!document.querySelector('.text-area-appendix--warning') && (count >= limit)) {
            const parent = document.querySelector('.textarea-with-count-container');
            const div = document.createElement('div');
            div.className = 'row text-area-appendix--warning';
            const p = document.createElement('p');
            const img = document.createElement('img');
            p.className = 'd-flex align-items-center p-0 kontrola-info--warning my-1';
            p.textContent = 'Dosažen limit počtu znaků.';
            img.setAttribute('src', warningUnfilled);
            img.setAttribute('alt', 'upozorneni');
            img.className = 'me-2';
            p.insertAdjacentElement('afterbegin', img);
            div.appendChild(p);
            parent.insertAdjacentElement('afterend', div);
        } else if (document.querySelector('.text-area-appendix--warning') && (count < limit)) {
            document.querySelector('.text-area-appendix--warning').remove();
        }

    }, [limit, count, allow]);

    return (
        <Container className='textarea-counter d-flex flex-column'>
            <Row className='align-self-end'><Row className='bg-white textarea-couner-text'>{`${count}/${limit}`}</Row></Row>
            {/* {(count >= limit) && <Row><p className='d-flex align-items-center p-0 kontrola-info--warning ps-3 my-1'><img src={warningUnfilled} alt="infoCircle" className='me-2' />Dosažen limit počtu znaků.</p></Row>}
            {(limit === 10000 && count >= 1000) && <Row className='mb-2'><p className='d-flex align-items-center p-0 kontrola-info ps-3 my-1'><img src={infoCircle} alt="infoCircle" className='me-2' />Text bude automaticky přiložen k žádosti jako příloha, neboť překročil 1000 znaků.</p></Row>} */}
        </Container>
    );
};

CharCount.propTypes = {
    count: PropTypes.number,
    limit: PropTypes.number,
    setCount: PropTypes.func,
    propToUpdate: PropTypes.number,
    value: PropTypes.string,
};

export default CharCount;