import React, { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { AuthContext } from 'react-oauth2-code-pkce';
import { GovButton } from '@gov-design-system-ce/react';
import { isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import { downloadDocumentVersion } from './utils';
import { formatedDate } from './DokumentaceVersionCard.jsx';

const DokumentaceVersionsTable = ({ items }) => {
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState({});

    const handleClick = async (bppPid, token) => {
        if (!bppPid) {
            return;
        }

        try {
            setIsLoading((prevState) => ({
                ...prevState,
                [bppPid]: true,
            }));
            const response = await downloadDocumentVersion(bppPid, token);

            if (isRequestOK(response?.status)) {
                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'dokumentace.zip';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }

            setIsLoading((prevState) => ({
                ...prevState,
                [bppPid]: false,
            }));
        } catch (error) {
            setIsLoading((prevState) => ({
                ...prevState,
                [bppPid]: false,
            }));
        }
    };

    return (
        <Container className='p-0 m-0'>
            <table className='border w-100'>
                <thead>
                    <tr>
                        <th className='py-2 px-3'><p className='table-head py-2 d-flex'>Datum</p></th>
                        <th className='py-2 px-3'><p className='table-head py-2 d-flex'>ID dokumentace</p></th>
                    </tr>
                </thead>
                <tbody>
                    {items?.length > 0 && items?.map((item, idx) => {
                        const isLoadingFile = !!isLoading?.[item?.bppPid];
                        const date = formatedDate(item?.finishDate);

                        return (
                            <tr key={item?.id ?? idx}>
                                <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={date}>{date}</p></td>
                                <td className='py-2 px-3'><p className='row-number py-2 text-truncate' title={item?.bppPid}>{item?.bppPid}</p></td>
                                <td className='py-2 px-3'>
                                    <GovButton
                                        nativeType='button'
                                        className='w-100'
                                        iconLeft='basic/file-earmark-arrow-down'
                                        type='outlined'
                                        variant='primary'
                                        expanded={true}
                                        disabled={isLoadingFile}
                                        aria-disabled={isLoadingFile}
                                        loading={isLoadingFile}
                                        onGov-click={() => handleClick(item?.bppPid, token)}
                                    >
                                        Stáhnout
                                    </GovButton>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Container>
    );
};

DokumentaceVersionsTable.propTypes = {
    items: PropTypes.any,
};

export default DokumentaceVersionsTable;